@media only screen and (min-width: 600px) {
  #price-list {
    grid-template-columns: 100px auto 100px;
  }
}

@media only screen and (max-width: 599px) {
  #price-list {
    grid-template-columns: 30px auto 30px;
  }
}

#price-list {
  display: grid;
  min-height: 100vh;
  grid-template-rows: 50px auto 50px; 
}

#price {
  font-weight: 500;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

#price h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.items {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-rows: 0.3fr 2fr 0.3fr;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
}

.item {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}

.price {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  justify-self: end;
}

.categories {
  margin-top: 30px;
}

.items b {
  font-weight: 500;
}
