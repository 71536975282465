#header {
  max-width: 100%;
  padding: 25px 0;
  height: 30px;
  position: fixed;
  width: 100%;
  z-index: 150;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
  transition: all 1s;
}

#header.shrink {
  padding: 15px 0;
  border-bottom: none;
  background-color: black;
}

#header h1 {
  float: left;
  font-family: 'Raleway', sans-serif;
  font-size: 1.2em;
  font-weight: 700;
  margin-left: 30px;
  color: white;
}

#nav {
  margin-right: 30px;
  text-align: center;
  float: right;
}

#nav a {
  display: inline-block;
  text-decoration: none;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
  font-family: 'source sans pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: .24em;
}

#nav a:hover,
#nav a:active {
  color: #ff9061;
}

#mobile-nav button {
  float: right;
  color: white;
  margin-right: 20px;
}

#reservation {
  margin: 10px;
  text-align: left;
}

#reservation h4 {
  font-size: 13px;
  font-weight: 600;
  margin: 8px 0;
}

#reservation a {
  font-weight: 400;
}

#nav-side-list {
  width: 250px;
  text-transform: uppercase;
  text-align: center;
}

#nav-side-list-logo {
  margin-top: 20px;
}

@media only screen and (max-width: 599px) {
  #header h1 {
    font-size: 1em;
    margin-left: 20px;
    margin-top: 10px;
  }
}
/* Main Image Components */

.main-img {
  background-size: cover;
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.main-img-body {
  position: absolute; 
  top: 45%;
  left: 50%;
  width: 90%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.main-img h1 {
  color: white;
  font-family: 'Playfair Display', serif;
  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 1rem;
}

.main-img h2 {
  color: white;
  text-transform: uppercase;
  font-size: 1.2rem;
  text-align: center;
}

.main-img a {
  display: block;
  text-align: center;
  margin: 20px auto;
}

.main-img img {
  position: fixed;
  bottom: 5%;
  right: 50%;
}

@media only screen and (max-width: 599px) {
  .main-img {
    background-attachment: scroll;
    background-attachment: initial;
    height: 500px;
  }
  .main-img h1 {
    font-size: 1.1em;
  }
}

/* What do you need component */

#what-do-you-need h3 {
  font-size: 1.6rem;
  font-family: 'Playfair Display', serif;
  line-height: 1.4em;
  width: 90%;
  margin: 80px auto 16px auto;
}

#what-do-you-need p {
  text-align: center;
  margin-bottom: 80px;
  font-size: 1.2rem;
}

#what-do-you-need h2,
#gallery h2 {
  font-size: 28px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #373737;
  letter-spacing: 0.03em;
  padding-bottom: 60px;
}

#gallery {
  margin-top: 100px;
}

@media only screen and (max-width: 599px) {
  #what-do-you-need {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  #what-do-you-need h2 {
    font-size: 18px;
  }

  #what-do-you-need h3 {
    font-size: 12px;
  }
}

/* Service Comonents */

#serviceComponent-wrapper {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.mainBody-serviceComponent {
  height: 240px;
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
  align-items: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.5s;
  overflow: hidden;
  position: relative;
}

.mainBody-serviceComponent::before { 
  content:"";
  position:absolute; top:0;right:0;bottom:0;left:0;
  background:inherit;
  transition:inherit;
}
.mainBody-serviceComponent:hover::before { 
  -webkit-transform: scale(1.2); 
          transform: scale(1.2);
}

.mainBody-serviceComponent-title {
  z-index: 10;
  background-color: white;
  padding: 15px;
  height: 21px;
  display: inline-block;
}

.mainBody-serviceComponent-title h1 {
  color: #626262;
  font-size: 15px;
  margin-bottom: 0;
  font-weight: 600;
  letter-spacing: 0.02em;
}

.mainBody-serviceComponent-readMore {
  height: 65px;
  width: 65px;
  background-color: #92A08C;
  z-index: 10;
}

.mainBody-serviceComponent-readMore h2 {
  color: white;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-align: center;
  padding-top: 15px;
}

@media only screen and (max-width: 599px) {
  #serviceComponent-wrapper {
    width: 100vw;
    flex-direction: column;
  }

  .mainBody-serviceComponent-title {
    padding: 20px;
  }
}

@media only screen and (min-width: 600px) {
  .mainBody-serviceComponent {
    width: calc(99.5vw/3);
  }
}

/* MiniAbout Component */
#miniAbout {
  height: 660px; 
  background: linear-gradient(
    rgba(255, 255, 255, 0.4), 
    rgba(255, 255, 255, 0.4)),
    url(/static/media/spa-background7.4ea48e04.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 100px;
}

h2.mainBody-heading {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: #373737;
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  padding-top: 100px;
}

#miniAbout-body {
  width: 370px;
  margin-left: 70px;
  padding-top: 70px;
}

#miniAbout p {
  line-height: 1.75;
  margin-bottom: 20px;
  font-weight: 400;
}

#miniAbout b {
  font-weight: 500;
  font-size: 1.3em;
}

#miniAbout button {
  width: 100px;
  height: 30px;
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  font-weight: 400;
  background-color: transparent;
}

@media only screen and (max-width: 599px) {
  #miniAbout-body {
    width: 90%;
    margin: 0 auto;
  }
}

/* Num Link Components */

.num-link h3 {
  font-family: 'arno-pro', sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 0.8em;
  letter-spacing: .1em;
  line-height: 3em;
  text-transform: none;
  text-align: left;
}

.num-link a {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.3em;
  letter-spacing: .25em;
  line-height: 1.5em;
  text-transform: uppercase;
  color: black;
}

@media only screen and (min-width: 710px) {
  .num-link {
    margin: 100px auto;
    display: flex;
  }
  
  .num-link.left {
    flex-direction: row-reverse;
    margin-left: 100px;
    justify-content: flex-end;
  }
  
  .num-link.right {
    flex-direction: row;
    margin-right: 100px;
    justify-content: flex-end;
  }

  .num-link-title {
    margin: 100px 30px 0 30px;
  }

  .num-link img {
    width: 300px;
    height: 350px;
    opacity: 0.7;
    object-fit: cover;
  }

  #quote {
    width: 350px;
    position: absolute;
    top: 30px;
    left: 50px;
  }
}

@media only screen and (max-width: 710px) {
  .num-link {
    margin: 100px auto;
    width: 85vw;
  }

  .num-link img {
    margin-top: 20px;
    width: 100%;
    height: 300px;
    opacity: 0.7;
    object-fit: cover;
  }

  .num-link-title {
    margin-left: -10px;
  }

  #main-img {
    position: static;
    height: 300px;
  }

  #quote {
    width: 350px;
  }
}
.bookNow {
  height: 500px;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.book-button {
  margin: 100px auto;
  width: 300px; 
}

.book-button button {
  width: 100%;
  height: 60px;
  color: white;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9em;
  font-weight: 700;
  position: relative;
}

/* .book-button button:hover {
  color: #ff773c;
  background-color: white;
} */

/* book box */
.material-icons.md-gray {
   color: #808080; 
}

.book-box-contact {
  margin: 20px 10px;
}

.book-box button {
  margin: 10px;
  display: block;
}

@media only screen and (min-width: 860px) {
  .book-box-wrapper {
    width: 330px;
    border: 1px solid #e5e5e5;
    height: 230px;
  }

  .book-box {
    width: 280px;
    margin: 30px auto;
  }
}

@media only screen and (max-width: 859px) {
  .book-box-wrapper {
    width: 80%;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }

  .book-box {
    width: 90%;
    margin: 30px auto;
  }
}

.book-box h3 {
  text-align: left;
  text-align: initial;
  color: rgb(88, 88, 88);
  line-height: 1.1em;
  font-size: 1.6em;
  margin: 0 auto;
}

.book-box-contact a {
  color: #808080;
  font-weight: 400;
  vertical-align: top;
  font-size: 1.1em;
  padding-left: 15px;
}
.testimonial-wrapper {
  margin-top: 100px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
}

.what-people-say h2 {
  font-weight: 200;
  font-size: 2rem;
  letter-spacing: 2px;
  color: #373737;
  text-align: left;
  margin-bottom: 15px;
}

.what-people-say p {
  line-height: 22px;
  font-size: 18px;
}

.what-people-say b {
  font-weight: 900;
}

.comment {
  background-color: rgb(34, 34, 34);
  min-height: 80px;
  color: white;
}

.comment p {
  padding: 20px;
  font-size: 16px;
  line-height: 24px;
  color: white;
}

.avatar-wrapper {
  margin-top: 20px;
}

@media only screen and (max-width: 599px) {
  .testimonial-wrapper {
    height: 600px;
  }

  .what-people-say {
    width: 80%;
    margin: 0 auto;
    padding-top: 60px;
  }

  .what-people-say h2 {
    text-align: center;
  }

  .comments-wrapper {
    width: 90%;
    padding-top: 20px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 600px) {
  .what-people-say {
    width: 40%;
    float: left;
    margin-left: 8%;
    margin-right: 2%;
    padding-top: 100px;
  }

  .comments-wrapper {
    float: right;
    width: 40%;
    margin-left: 3%;
    margin-right: 7%;
    padding-top: 50px;
  }
}
#about {
  margin: 80px auto;
}

#about h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.8em;
  line-height: 1.4em;
  letter-spacing: .25em;
  text-transform: uppercase;
  color: #222;
  margin-bottom: 40px;
  text-align: center;
}

#about p {
  font-family: 'Monserrat', sans-serif;
  margin-bottom: 25px;
  font-weight: 300;
  font-style: normal;
  font-size: 13.5px;
  line-height: 2.4em;
  letter-spacing: .065em;
  color: #1b1b1b;
}

@media only screen and (min-width: 600px) {
  #about {
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  #about {
    max-width: 300px;
  }
}

#contact {
  margin-top: 100px;
}

#contact-info h1 {
  margin: 30px 0 10px 0;
  text-align: inherit;
}

#contact-info h5,
#contact-info a {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  display: block;
  margin-left: 0;
  color: black;
  font-weight: 300;
  letter-spacing: .1em;
  line-height: 2em;
  font-size: 0.9em;
}

@media only screen and (min-width: 716px) {
  #contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  #contact-info {
    margin-right: 80px;
    margin-left: 50px;
  }

  #map {
    flex-grow: 1;
    margin-left: 80px;
  }
}

@media only screen and (max-width: 715px) {
  #contact-info {
    width: 200px;
    margin: 100px auto;
    text-align: center;
  }

  #map {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }
}


.service h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  text-align: center;
  letter-spacing: 0.02em;
  margin: 20px 0;
  font-weight: 600;
}

.service-center img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  margin: 70px auto 40px auto;
}

.service p {
  width: 80%;
  margin: 20px auto 30px auto;
  font-size: 0.9em;
  letter-spacing: 0.02em;
  line-height: 23px;
  text-align: center;
}

.service-center-link {
  display: block;
  margin: 20px auto;
}

#select-treatment {
  width: 600px;
  margin: 20px auto 50px auto;
}

#select-treatment a {
  font-size: 0.85em;
  color: #9b9b9b;
  margin-left: 30px;
  margin-right: 30px;
  cursor: pointer;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  letter-spacing: 0.15em;
  line-height: 30px;
}

#select-treatment a:hover,
#select-treatment a:active {
  color: #555;
}

#select-treatment .active {
  position: relative;
  color: #555;
}

#select-treatment .active:before {
  background: #ff9061;
  content: '';
  position: absolute;
  height: 5px;
  z-index: -1;
  left: 0;
  right: 0%;
  bottom: 0;
}

@media only screen and (min-width: 600px) {
  .service {
    width: 860px;
    height: 480px;
    margin: 200px auto 60px auto; 
    position: relative;
  }
  
  .service-left-img,
  .service-right-img {
    width: 280px;
    height: 350px;
  }

  .service-left-img {
    float: left;
  }

  .service-right-img {
    float: right;
  }
  
  .service-center {
    display: inline-block;
    max-width: 280px;
    background-color: #f6f6f6;
    margin: 0 10px;
    position: absolute;
    top: -70px;
  }

  .border-underline-from-left {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    overflow: hidden;
  }
  
  .border-underline-from-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    background: #ff9061;
    height: 5px;
    transition-property: right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  
  .border-underline-from-left:hover:before {
    right: 0;
  }
}

@media only screen and (max-width: 599px) {
  .service {
    width: 100vw;
  }
  
  .service-left-img,
  .service-right-img {
    display: block;
    width: 95vw;
    height: 95vw;
    margin: 20px auto;
  }
  
  .service-center {
    width: 95%;
    height: 500px;
    background-color: #f6f6f6;
    margin: 20px auto;
  }

  .service-center img {
    padding: 30px 0;
    margin: 0 auto;
  }

  #select-treatment {
    width: 80vw;
  }
  
  #select-treatment a {
    display: block;
    margin: 20px auto;
  }
}
#rate-components-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 70px;
}

.rate-component {
  width: 350px;
  height: 380px;
  margin: 20px;
  padding: 30px;
  border: 2px solid white;
  position: relative;
  transition: color 0.25s;
  flex-direction: row;
}

.rate-component img {
  width: 100%;
  height: 230px;
}

.rate-component-left {
  float: left;
}

.rate-component-right {
  float: right;
}

.rate-component h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  text-align: center;
  letter-spacing: 0.02em;
  margin: 20px 0;
  font-weight: 600;
}

.rate-component p {
  font-size: 1em;
  letter-spacing: 0.05em;
  line-height: 30px;
}

/* Rate-component border animation */

.rate-component::before,
::after {
  box-sizing: inherit;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  width: 0;
  height: 0;
}

.rate-component::before {
  top: 0;
  left: 0;
}

.rate-component::after {
  bottom: 0;
  right: 0;
}

.rate-component:hover {
  color: #ff9061;
  border: none;
  transition: all 1s;
}

.rate-component:hover::before {
  width: 100%;
  height: 100%;
  border-top-color: #ff9061;
  border-right-color: #ff9061;
  transition:
    width 0.25s ease-out,
    height 0.25s ease-out 0.25s;
}

.rate-component:hover::after {
  width: 100%;
  height: 100%;
  border-bottom-color: #ff9061;
  border-left-color: #ff9061;
  transition:
    border-color 0s ease-out 0.5s,
    width 0.25s ease-out 0.5s,
    height 0.25s ease-out 0.75s;
}

@media only screen and (min-width: 600px) {
  #price-list {
    grid-template-columns: 100px auto 100px;
  }
}

@media only screen and (max-width: 599px) {
  #price-list {
    grid-template-columns: 30px auto 30px;
  }
}

#price-list {
  display: grid;
  min-height: 100vh;
  grid-template-rows: 50px auto 50px; 
}

#price {
  font-weight: 500;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

#price h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.items {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-rows: 0.3fr 2fr 0.3fr;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
}

.item {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}

.price {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  justify-self: end;
}

.categories {
  margin-top: 30px;
}

.items b {
  font-weight: 500;
}

@font-face {
	font-family: 'Pe-icon-7-stroke';
	src:url(/static/media/Pe-icon-7-stroke.71394c0c.eot);
	src:url(/static/media/Pe-icon-7-stroke.71394c0c.eot?#iefixd7yf1v) format('embedded-opentype'),
		url(/static/media/Pe-icon-7-stroke.b38ef310.woff) format('woff'),
		url(/static/media/Pe-icon-7-stroke.01798bc1.ttf) format('truetype'),
		url(/static/media/Pe-icon-7-stroke.c45f7de0.svg#Pe-icon-7-stroke) format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="pe-7s-"], [class*=" pe-7s-"] {
	display: inline-block;
	font-family: 'Pe-icon-7-stroke';
	speak: none;
	font-style: normal;
	font-weight: normal;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.pe-7s-album:before {
	content: "\E6AA";
}
.pe-7s-arc:before {
	content: "\E6AB";
}
.pe-7s-back-2:before {
	content: "\E6AC";
}
.pe-7s-bandaid:before {
	content: "\E6AD";
}
.pe-7s-car:before {
	content: "\E6AE";
}
.pe-7s-diamond:before {
	content: "\E6AF";
}
.pe-7s-door-lock:before {
	content: "\E6B0";
}
.pe-7s-eyedropper:before {
	content: "\E6B1";
}
.pe-7s-female:before {
	content: "\E6B2";
}
.pe-7s-gym:before {
	content: "\E6B3";
}
.pe-7s-hammer:before {
	content: "\E6B4";
}
.pe-7s-headphones:before {
	content: "\E6B5";
}
.pe-7s-helm:before {
	content: "\E6B6";
}
.pe-7s-hourglass:before {
	content: "\E6B7";
}
.pe-7s-leaf:before {
	content: "\E6B8";
}
.pe-7s-magic-wand:before {
	content: "\E6B9";
}
.pe-7s-male:before {
	content: "\E6BA";
}
.pe-7s-map-2:before {
	content: "\E6BB";
}
.pe-7s-next-2:before {
	content: "\E6BC";
}
.pe-7s-paint-bucket:before {
	content: "\E6BD";
}
.pe-7s-pendrive:before {
	content: "\E6BE";
}
.pe-7s-photo:before {
	content: "\E6BF";
}
.pe-7s-piggy:before {
	content: "\E6C0";
}
.pe-7s-plugin:before {
	content: "\E6C1";
}
.pe-7s-refresh-2:before {
	content: "\E6C2";
}
.pe-7s-rocket:before {
	content: "\E6C3";
}
.pe-7s-settings:before {
	content: "\E6C4";
}
.pe-7s-shield:before {
	content: "\E6C5";
}
.pe-7s-smile:before {
	content: "\E6C6";
}
.pe-7s-usb:before {
	content: "\E6C7";
}
.pe-7s-vector:before {
	content: "\E6C8";
}
.pe-7s-wine:before {
	content: "\E6C9";
}
.pe-7s-cloud-upload:before {
	content: "\E68A";
}
.pe-7s-cash:before {
	content: "\E68C";
}
.pe-7s-close:before {
	content: "\E680";
}
.pe-7s-bluetooth:before {
	content: "\E68D";
}
.pe-7s-cloud-download:before {
	content: "\E68B";
}
.pe-7s-way:before {
	content: "\E68E";
}
.pe-7s-close-circle:before {
	content: "\E681";
}
.pe-7s-id:before {
	content: "\E68F";
}
.pe-7s-angle-up:before {
	content: "\E682";
}
.pe-7s-wristwatch:before {
	content: "\E690";
}
.pe-7s-angle-up-circle:before {
	content: "\E683";
}
.pe-7s-world:before {
	content: "\E691";
}
.pe-7s-angle-right:before {
	content: "\E684";
}
.pe-7s-volume:before {
	content: "\E692";
}
.pe-7s-angle-right-circle:before {
	content: "\E685";
}
.pe-7s-users:before {
	content: "\E693";
}
.pe-7s-angle-left:before {
	content: "\E686";
}
.pe-7s-user-female:before {
	content: "\E694";
}
.pe-7s-angle-left-circle:before {
	content: "\E687";
}
.pe-7s-up-arrow:before {
	content: "\E695";
}
.pe-7s-angle-down:before {
	content: "\E688";
}
.pe-7s-switch:before {
	content: "\E696";
}
.pe-7s-angle-down-circle:before {
	content: "\E689";
}
.pe-7s-scissors:before {
	content: "\E697";
}
.pe-7s-wallet:before {
	content: "\E600";
}
.pe-7s-safe:before {
	content: "\E698";
}
.pe-7s-volume2:before {
	content: "\E601";
}
.pe-7s-volume1:before {
	content: "\E602";
}
.pe-7s-voicemail:before {
	content: "\E603";
}
.pe-7s-video:before {
	content: "\E604";
}
.pe-7s-user:before {
	content: "\E605";
}
.pe-7s-upload:before {
	content: "\E606";
}
.pe-7s-unlock:before {
	content: "\E607";
}
.pe-7s-umbrella:before {
	content: "\E608";
}
.pe-7s-trash:before {
	content: "\E609";
}
.pe-7s-tools:before {
	content: "\E60A";
}
.pe-7s-timer:before {
	content: "\E60B";
}
.pe-7s-ticket:before {
	content: "\E60C";
}
.pe-7s-target:before {
	content: "\E60D";
}
.pe-7s-sun:before {
	content: "\E60E";
}
.pe-7s-study:before {
	content: "\E60F";
}
.pe-7s-stopwatch:before {
	content: "\E610";
}
.pe-7s-star:before {
	content: "\E611";
}
.pe-7s-speaker:before {
	content: "\E612";
}
.pe-7s-signal:before {
	content: "\E613";
}
.pe-7s-shuffle:before {
	content: "\E614";
}
.pe-7s-shopbag:before {
	content: "\E615";
}
.pe-7s-share:before {
	content: "\E616";
}
.pe-7s-server:before {
	content: "\E617";
}
.pe-7s-search:before {
	content: "\E618";
}
.pe-7s-film:before {
	content: "\E6A5";
}
.pe-7s-science:before {
	content: "\E619";
}
.pe-7s-disk:before {
	content: "\E6A6";
}
.pe-7s-ribbon:before {
	content: "\E61A";
}
.pe-7s-repeat:before {
	content: "\E61B";
}
.pe-7s-refresh:before {
	content: "\E61C";
}
.pe-7s-add-user:before {
	content: "\E6A9";
}
.pe-7s-refresh-cloud:before {
	content: "\E61D";
}
.pe-7s-paperclip:before {
	content: "\E69C";
}
.pe-7s-radio:before {
	content: "\E61E";
}
.pe-7s-note2:before {
	content: "\E69D";
}
.pe-7s-print:before {
	content: "\E61F";
}
.pe-7s-network:before {
	content: "\E69E";
}
.pe-7s-prev:before {
	content: "\E620";
}
.pe-7s-mute:before {
	content: "\E69F";
}
.pe-7s-power:before {
	content: "\E621";
}
.pe-7s-medal:before {
	content: "\E6A0";
}
.pe-7s-portfolio:before {
	content: "\E622";
}
.pe-7s-like2:before {
	content: "\E6A1";
}
.pe-7s-plus:before {
	content: "\E623";
}
.pe-7s-left-arrow:before {
	content: "\E6A2";
}
.pe-7s-play:before {
	content: "\E624";
}
.pe-7s-key:before {
	content: "\E6A3";
}
.pe-7s-plane:before {
	content: "\E625";
}
.pe-7s-joy:before {
	content: "\E6A4";
}
.pe-7s-photo-gallery:before {
	content: "\E626";
}
.pe-7s-pin:before {
	content: "\E69B";
}
.pe-7s-phone:before {
	content: "\E627";
}
.pe-7s-plug:before {
	content: "\E69A";
}
.pe-7s-pen:before {
	content: "\E628";
}
.pe-7s-right-arrow:before {
	content: "\E699";
}
.pe-7s-paper-plane:before {
	content: "\E629";
}
.pe-7s-delete-user:before {
	content: "\E6A7";
}
.pe-7s-paint:before {
	content: "\E62A";
}
.pe-7s-bottom-arrow:before {
	content: "\E6A8";
}
.pe-7s-notebook:before {
	content: "\E62B";
}
.pe-7s-note:before {
	content: "\E62C";
}
.pe-7s-next:before {
	content: "\E62D";
}
.pe-7s-news-paper:before {
	content: "\E62E";
}
.pe-7s-musiclist:before {
	content: "\E62F";
}
.pe-7s-music:before {
	content: "\E630";
}
.pe-7s-mouse:before {
	content: "\E631";
}
.pe-7s-more:before {
	content: "\E632";
}
.pe-7s-moon:before {
	content: "\E633";
}
.pe-7s-monitor:before {
	content: "\E634";
}
.pe-7s-micro:before {
	content: "\E635";
}
.pe-7s-menu:before {
	content: "\E636";
}
.pe-7s-map:before {
	content: "\E637";
}
.pe-7s-map-marker:before {
	content: "\E638";
}
.pe-7s-mail:before {
	content: "\E639";
}
.pe-7s-mail-open:before {
	content: "\E63A";
}
.pe-7s-mail-open-file:before {
	content: "\E63B";
}
.pe-7s-magnet:before {
	content: "\E63C";
}
.pe-7s-loop:before {
	content: "\E63D";
}
.pe-7s-look:before {
	content: "\E63E";
}
.pe-7s-lock:before {
	content: "\E63F";
}
.pe-7s-lintern:before {
	content: "\E640";
}
.pe-7s-link:before {
	content: "\E641";
}
.pe-7s-like:before {
	content: "\E642";
}
.pe-7s-light:before {
	content: "\E643";
}
.pe-7s-less:before {
	content: "\E644";
}
.pe-7s-keypad:before {
	content: "\E645";
}
.pe-7s-junk:before {
	content: "\E646";
}
.pe-7s-info:before {
	content: "\E647";
}
.pe-7s-home:before {
	content: "\E648";
}
.pe-7s-help2:before {
	content: "\E649";
}
.pe-7s-help1:before {
	content: "\E64A";
}
.pe-7s-graph3:before {
	content: "\E64B";
}
.pe-7s-graph2:before {
	content: "\E64C";
}
.pe-7s-graph1:before {
	content: "\E64D";
}
.pe-7s-graph:before {
	content: "\E64E";
}
.pe-7s-global:before {
	content: "\E64F";
}
.pe-7s-gleam:before {
	content: "\E650";
}
.pe-7s-glasses:before {
	content: "\E651";
}
.pe-7s-gift:before {
	content: "\E652";
}
.pe-7s-folder:before {
	content: "\E653";
}
.pe-7s-flag:before {
	content: "\E654";
}
.pe-7s-filter:before {
	content: "\E655";
}
.pe-7s-file:before {
	content: "\E656";
}
.pe-7s-expand1:before {
	content: "\E657";
}
.pe-7s-exapnd2:before {
	content: "\E658";
}
.pe-7s-edit:before {
	content: "\E659";
}
.pe-7s-drop:before {
	content: "\E65A";
}
.pe-7s-drawer:before {
	content: "\E65B";
}
.pe-7s-download:before {
	content: "\E65C";
}
.pe-7s-display2:before {
	content: "\E65D";
}
.pe-7s-display1:before {
	content: "\E65E";
}
.pe-7s-diskette:before {
	content: "\E65F";
}
.pe-7s-date:before {
	content: "\E660";
}
.pe-7s-cup:before {
	content: "\E661";
}
.pe-7s-culture:before {
	content: "\E662";
}
.pe-7s-crop:before {
	content: "\E663";
}
.pe-7s-credit:before {
	content: "\E664";
}
.pe-7s-copy-file:before {
	content: "\E665";
}
.pe-7s-config:before {
	content: "\E666";
}
.pe-7s-compass:before {
	content: "\E667";
}
.pe-7s-comment:before {
	content: "\E668";
}
.pe-7s-coffee:before {
	content: "\E669";
}
.pe-7s-cloud:before {
	content: "\E66A";
}
.pe-7s-clock:before {
	content: "\E66B";
}
.pe-7s-check:before {
	content: "\E66C";
}
.pe-7s-chat:before {
	content: "\E66D";
}
.pe-7s-cart:before {
	content: "\E66E";
}
.pe-7s-camera:before {
	content: "\E66F";
}
.pe-7s-call:before {
	content: "\E670";
}
.pe-7s-calculator:before {
	content: "\E671";
}
.pe-7s-browser:before {
	content: "\E672";
}
.pe-7s-box2:before {
	content: "\E673";
}
.pe-7s-box1:before {
	content: "\E674";
}
.pe-7s-bookmarks:before {
	content: "\E675";
}
.pe-7s-bicycle:before {
	content: "\E676";
}
.pe-7s-bell:before {
	content: "\E677";
}
.pe-7s-battery:before {
	content: "\E678";
}
.pe-7s-ball:before {
	content: "\E679";
}
.pe-7s-back:before {
	content: "\E67A";
}
.pe-7s-attention:before {
	content: "\E67B";
}
.pe-7s-anchor:before {
	content: "\E67C";
}
.pe-7s-albums:before {
	content: "\E67D";
}
.pe-7s-alarm:before {
	content: "\E67E";
}
.pe-7s-airplay:before {
	content: "\E67F";
}

#footer {
  clear: both;
  margin-top: 100px;
}

.footer-block {
  margin-top: 30px;
  text-align: center;
  line-height: 2em;
  float: left;
}

.footer-block h2 {
  font-family: 'arno-pro';
  font-weight: 700;
  font-style: normal;
  font-size: 11.5px;
  line-height: 2.1em;
  letter-spacing: .25em;
  margin: 10px 0;
}

.footer-block p,
#company a,
.contact a {
  font-family: 'arno-pro';
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 1em;
  letter-spacing: .05em;
  margin: 10px 0;
  display: block;
  cursor: pointer;
}

.footer-block p,
.contact a {
  line-height: 1.5em;
}

.footer-block p {
  cursor: auto;   
}

#yelp-icon {
  width: 100px;
}

#madeByBanner {
  padding: 40px 0;
  clear: both;
  font-family: 'arno-pro';
  font-size: 13px;
  font-weight: 400;
}

#follow-icons a {
  margin-left: 0;
  margin-right: 10px;
}

@media only screen and (min-width: 600px) {
  .footer-block {
    width: calc(100vw/4);
  }
}

@media only screen and (max-width: 599px) {
  .footer-block {
    width: calc(100vw/2);
  }

  #footer {
    clear: both;
    margin-top: 30px;
  }
}

#end-img {
  background-image: url('https://static1.squarespace.com/static/5755bccb8259b515333df0e1/t/576336d3ff7c507e597e35de/1466119899593/Stocksy_comp_821143-crop.jpg?format=2500w');
  height: 100vh; 
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#end-img-address {
  padding-top: 30%;
  text-align: center;
  margin: 0 auto;
}

#end-img a,
#end-img h4 {
  color: white;
  font-family: 'Playfair Display', serif;
  text-align: center;
  font-size: 1.1em;
  display: inline-block;
}

#end-img h1 {
  color: white;
  font-family: 'Playfair Display', serif;
  padding-top: 30px;
}


#gift-cards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 50px 30px;
}

.card {
  width: 300px;
  height: 340px;
  flex: 1 0;
  margin-bottom: 30px;
}

.card img {
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
}

.card h3 {
  margin-bottom: 20px;
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 17px;
  color: #4a0000;
}

.card button {
  width: 270px;
  height: 40px;
  font-family: 'Lato', sans-serif;
  font-weight: 800;
  font-size: 0.9em;
  color: white;
  background-color: #6f0000;
  border-radius: 5px;
  border: none;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.details-wrapper {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.4);
  width: 100%;
  height: 100%;
}

.details {
  margin: 15% auto;
  background-color: #fbfbfb;
  width: 80vw;
  height: 70vh;
  overflow: scroll;
}

.details h2 {
  text-align: center;
  text-transform: uppercase;
  padding: 30px 0; 
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  color: #4a0000;
}

.details p {
  width: 90%;
  margin: 30px auto;
  color: #190000;
  line-height: 25px;
} 

.details h4 {
  width: 90%;
  margin: 20px auto;
  line-height: 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 1em;
  color: #4a0000;
} 

form[name='gc-form'] {
  width: 90%;
  margin: 20px auto;
}

.gc-form-items {
  padding: 15px;
}

.gc-form-items label {
  margin-right: 10px;
  display:inline-block;
  vertical-align: middle;
  font-family: 'Raleway', sans-serif;
  color: #190000;
}

.gc-form-items input,
.gc-form-items textarea,
.gc-form-items select {
  border: none;
  border-bottom: 1px solid #190000;
  background-color: transparent;
  color: #5f0000;
  font-size: 0.9em;
}

.gc-form-items input {
  height: 20px;
}

.gc-form-items textarea {
  height: 50px;
  display: inline-block;
  vertical-align: middle;
  width: 63%;
}

input[name='name'] {
  width: 63%;
}

input[name='value'] {
  width: 50%;
}

input[type='submit'] {
  background-color: #6f0000;
  width: 100%;
  color: white;
  padding: 10px;
  font-size: 1em;
  font-family: 'Lato', sans-serif;
  border-radius: 5px;
}

.gc-price-buy-wrapper {
  padding-top: 40px;
  display: flex;
  justify-content: flex-end;
  width: 85%;
}

.gc-buy-button {
  margin-left: 50px;
}

.gc-price {
  width: 50px;
  font-size: 2.2em;
  font-family: 'Source Sans Pro', sans-serif;
  color: #6f0000;
  font-weight: 300;
}

.service-selector {
  display: inline-block;
  width: 63%;
}

select[name='service'] {
  width: 100%;
}

.gc-buy-button button span{
  background-image: linear-gradient(rgb(113, 29, 18), rgb(113, 29, 18), rgb(113, 29, 18)) !important;
  width: 250px !important;
  padding: 3px !important;
}
/* Treatment */

.treatment-description h2 {
  font-family: 'Lato', sans-serif;
  font-size: 2em;
  letter-spacing: .05em;
  color: #4c4c4c;
  margin-bottom: 20px;
}

.treatment-description p {
  line-height: 1.5em;
}

@media only screen and (min-width: 860px) {
  .treatment-description {
    width: 42%;
    margin-left: 80px;
    float: left;
    margin-top: 100px;
  }

  .treatment-book-box {
    float: right;
    margin-right: 80px;
    margin-top: 80px;
  }
}

@media only screen and (max-width: 859px) {
  .treatment-description {
    width: 80%;
    margin: 100px auto 40px auto;
  }

  .treatment-book-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .treatment-description h2 {
    text-align: center;
  }

  .treatment-description p {
    text-align: center;
  }
}

/* MassageCards */

.massage-card h3,
.massage-card h4{
  font-size: 1.3em;
  font-weight: 500;
  color: #5e5e5e;
  margin-bottom: 20px;
}

.massage-card p,
.massage-card h5 {
  font-family: "raleway",sans-serif;
  font-weight: 500;
  color: #989898;
  margin-bottom: 20px;
  letter-spacing: .025em;
  padding: 10px;
  line-height: 1.8;
  font-size: .9rem;
  text-align: center;
}

.massage-card h5 {
  float: left; 
  padding-left: 20px;
}

.massage-card h4 {
  float: right;
  padding: 10px;
  padding-right: 20px;
}

/* Massage */

@media only screen and (min-width: 920px) {
  .massage-cards {
    width: 95%;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
  }
  .massage-card {
    width: 400px;
    margin: 30px 0;
    padding: 50px 15px 70px 15px; 
    border: 3px solid #e5e5e5;
    flex: 1 1 auto;
  }
}

@media only screen and (max-width: 919px) {
  .massage-card {
    width: 80%;
    margin: 0 auto;
    padding: 50px 0;
    border-bottom: 3px solid #e5e5e5;
  }
}

/* Acupuncture */
#treatment-acupuncture {
  width: 80%;
  margin: 40px auto;
}

.treatment-acupuncture-paragraph h3 {
  font-family: 'Lato', sans-serif;
  font-size: 1.5em;
  font-weight: 300;
  letter-spacing: .05em;
  color: #5e5e5e;
  padding-top: 50px;
  padding-bottom: 30px;
}

.treatment-acupuncture-paragraph h4 {
  float: left;
  font-size: 1.1em;
}

.treatment-acupuncture-paragraph h5 {
  float: right;
  font-size: 1.1em;
  margin-bottom: 20px;
}

.treatment-acupuncture-paragraph p,
.treatment-acupuncture-paragraph ul {
  line-height: 1.6;
  margin-bottom: 20px;
}

.treatment-acupuncture-paragraph ul {
  list-style-type: square;
  margin-left: 50px;
  color: #5e5e5e;
}

.treatment-acupuncture-paragraph b {
  font-weight: 400;
}

#reflexology-chart {
  margin-top: 20px;
  width: 100%;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-family: 'Source Sans Pro', sans-serif;
  color: rgb(74, 75, 78);
  font-size: 16px;
  letter-spacing: 0.05em;
}

p,
a {
  font-size: 16px;
  color: rgb(34, 34, 34);
  font-family: 'Source Sans Pro', sans-serif;
  text-decoration: none;
}

a {
  font-weight: 400;
}

h3 {
  font-family: 'Source Sans Pro', sans-serif;
  color: rgb(34, 34, 34);
  font-weight: 200;
  letter-spacing: 0.07em;
  font-size: 1.2em;
  text-align: center;
}

h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 1.8em;
  line-height: 1.4em;
  letter-spacing: .25em;
  color: #222;
  margin-bottom: 40px;
  text-align: center;
}

/* border box animation  */

.box {
  border: none;
  position: relative;
  background-color: transparent;
  font-size: 0.8em;
  padding: 10px;
  cursor: pointer;
}

.box::before,
::after {
  box-sizing: inherit;
  content: '';
  position: absolute;
  border: 2px solid transparent;
  width: 0;
  height: 0;
}

.box::before {
  top: 0;
  left: 0;
}

.box::after {
  bottom: 0;
  right: 0;
}

.box:hover {
  color: #ff9061;
  border: none;
  transition: all 1s;
}

.box:hover::before {
  width: 100%;
  height: 100%;
  border-top-color: #ff9061;
  border-right-color: #ff9061;
  transition:
    width 0.25s ease-out,
    height 0.25s ease-out 0.25s;
}

.box:hover::after {
  width: 100%;
  height: 100%;
  border-bottom-color: #ff9061;
  border-left-color: #ff9061;
  transition:
    border-color 0s ease-out 0.5s,
    width 0.25s ease-out 0.5s,
    height 0.25s ease-out 0.75s;
}

.hide {
  display: none;
}

.show {
  display: block;
}


.clearFloat {
  clear: both;
}
