.service h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  text-align: center;
  letter-spacing: 0.02em;
  margin: 20px 0;
  font-weight: 600;
}

.service-center img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  margin: 70px auto 40px auto;
}

.service p {
  width: 80%;
  margin: 20px auto 30px auto;
  font-size: 0.9em;
  letter-spacing: 0.02em;
  line-height: 23px;
  text-align: center;
}

.service-center-link {
  display: block;
  margin: 20px auto;
}

#select-treatment {
  width: 600px;
  margin: 20px auto 50px auto;
}

#select-treatment a {
  font-size: 0.85em;
  color: #9b9b9b;
  margin-left: 30px;
  margin-right: 30px;
  cursor: pointer;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  letter-spacing: 0.15em;
  line-height: 30px;
}

#select-treatment a:hover,
#select-treatment a:active {
  color: #555;
}

#select-treatment .active {
  position: relative;
  color: #555;
}

#select-treatment .active:before {
  background: #ff9061;
  content: '';
  position: absolute;
  height: 5px;
  z-index: -1;
  left: 0;
  right: 0%;
  bottom: 0;
}

@media only screen and (min-width: 600px) {
  .service {
    width: 860px;
    height: 480px;
    margin: 200px auto 60px auto; 
    position: relative;
  }
  
  .service-left-img,
  .service-right-img {
    width: 280px;
    height: 350px;
  }

  .service-left-img {
    float: left;
  }

  .service-right-img {
    float: right;
  }
  
  .service-center {
    display: inline-block;
    max-width: 280px;
    background-color: #f6f6f6;
    margin: 0 10px;
    position: absolute;
    top: -70px;
  }

  .border-underline-from-left {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    overflow: hidden;
  }
  
  .border-underline-from-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    background: #ff9061;
    height: 5px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  
  .border-underline-from-left:hover:before {
    right: 0;
  }
}

@media only screen and (max-width: 599px) {
  .service {
    width: 100vw;
  }
  
  .service-left-img,
  .service-right-img {
    display: block;
    width: 95vw;
    height: 95vw;
    margin: 20px auto;
  }
  
  .service-center {
    width: 95%;
    height: 500px;
    background-color: #f6f6f6;
    margin: 20px auto;
  }

  .service-center img {
    padding: 30px 0;
    margin: 0 auto;
  }

  #select-treatment {
    width: 80vw;
  }
  
  #select-treatment a {
    display: block;
    margin: 20px auto;
  }
}