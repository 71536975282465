/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-family: 'Source Sans Pro', sans-serif;
  color: rgb(74, 75, 78);
  font-size: 16px;
  letter-spacing: 0.05em;
}

p,
a {
  font-size: 16px;
  color: rgb(34, 34, 34);
  font-family: 'Source Sans Pro', sans-serif;
  text-decoration: none;
}

a {
  font-weight: 400;
}

h3 {
  font-family: 'Source Sans Pro', sans-serif;
  color: rgb(34, 34, 34);
  font-weight: 200;
  letter-spacing: 0.07em;
  font-size: 1.2em;
  text-align: center;
}

h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 1.8em;
  line-height: 1.4em;
  letter-spacing: .25em;
  color: #222;
  margin-bottom: 40px;
  text-align: center;
}

/* border box animation  */

.box {
  border: none;
  position: relative;
  background-color: transparent;
  font-size: 0.8em;
  padding: 10px;
  cursor: pointer;
}

.box::before,
::after {
  box-sizing: inherit;
  content: '';
  position: absolute;
  border: 2px solid transparent;
  width: 0;
  height: 0;
}

.box::before {
  top: 0;
  left: 0;
}

.box::after {
  bottom: 0;
  right: 0;
}

.box:hover {
  color: #ff9061;
  border: none;
  transition: all 1s;
}

.box:hover::before {
  width: 100%;
  height: 100%;
  border-top-color: #ff9061;
  border-right-color: #ff9061;
  transition:
    width 0.25s ease-out,
    height 0.25s ease-out 0.25s;
}

.box:hover::after {
  width: 100%;
  height: 100%;
  border-bottom-color: #ff9061;
  border-left-color: #ff9061;
  transition:
    border-color 0s ease-out 0.5s,
    width 0.25s ease-out 0.5s,
    height 0.25s ease-out 0.75s;
}

.hide {
  display: none;
}

.show {
  display: block;
}


.clearFloat {
  clear: both;
}