/* Treatment */

.treatment-description h2 {
  font-family: 'Lato', sans-serif;
  font-size: 2em;
  letter-spacing: .05em;
  color: #4c4c4c;
  margin-bottom: 20px;
}

.treatment-description p {
  line-height: 1.5em;
}

@media only screen and (min-width: 860px) {
  .treatment-description {
    width: 42%;
    margin-left: 80px;
    float: left;
    margin-top: 100px;
  }

  .treatment-book-box {
    float: right;
    margin-right: 80px;
    margin-top: 80px;
  }
}

@media only screen and (max-width: 859px) {
  .treatment-description {
    width: 80%;
    margin: 100px auto 40px auto;
  }

  .treatment-book-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .treatment-description h2 {
    text-align: center;
  }

  .treatment-description p {
    text-align: center;
  }
}

/* MassageCards */

.massage-card h3,
.massage-card h4{
  font-size: 1.3em;
  font-weight: 500;
  color: #5e5e5e;
  margin-bottom: 20px;
}

.massage-card p,
.massage-card h5 {
  font-family: "raleway",sans-serif;
  font-weight: 500;
  color: #989898;
  margin-bottom: 20px;
  letter-spacing: .025em;
  padding: 10px;
  line-height: 1.8;
  font-size: .9rem;
  text-align: center;
}

.massage-card h5 {
  float: left; 
  padding-left: 20px;
}

.massage-card h4 {
  float: right;
  padding: 10px;
  padding-right: 20px;
}

/* Massage */

@media only screen and (min-width: 920px) {
  .massage-cards {
    width: 95%;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
  }
  .massage-card {
    width: 400px;
    margin: 30px 0;
    padding: 50px 15px 70px 15px; 
    border: 3px solid #e5e5e5;
    flex: 1 1 auto;
  }
}

@media only screen and (max-width: 919px) {
  .massage-card {
    width: 80%;
    margin: 0 auto;
    padding: 50px 0;
    border-bottom: 3px solid #e5e5e5;
  }
}

/* Acupuncture */
#treatment-acupuncture {
  width: 80%;
  margin: 40px auto;
}

.treatment-acupuncture-paragraph h3 {
  font-family: 'Lato', sans-serif;
  font-size: 1.5em;
  font-weight: 300;
  letter-spacing: .05em;
  color: #5e5e5e;
  padding-top: 50px;
  padding-bottom: 30px;
}

.treatment-acupuncture-paragraph h4 {
  float: left;
  font-size: 1.1em;
}

.treatment-acupuncture-paragraph h5 {
  float: right;
  font-size: 1.1em;
  margin-bottom: 20px;
}

.treatment-acupuncture-paragraph p,
.treatment-acupuncture-paragraph ul {
  line-height: 1.6;
  margin-bottom: 20px;
}

.treatment-acupuncture-paragraph ul {
  list-style-type: square;
  margin-left: 50px;
  color: #5e5e5e;
}

.treatment-acupuncture-paragraph b {
  font-weight: 400;
}

#reflexology-chart {
  margin-top: 20px;
  width: 100%;
}