#contact {
  margin-top: 100px;
}

#contact-info h1 {
  margin: 30px 0 10px 0;
  text-align: inherit;
}

#contact-info h5,
#contact-info a {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  display: block;
  margin-left: 0;
  color: black;
  font-weight: 300;
  letter-spacing: .1em;
  line-height: 2em;
  font-size: 0.9em;
}

@media only screen and (min-width: 716px) {
  #contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  #contact-info {
    margin-right: 80px;
    margin-left: 50px;
  }

  #map {
    flex-grow: 1;
    margin-left: 80px;
  }
}

@media only screen and (max-width: 715px) {
  #contact-info {
    width: 200px;
    margin: 100px auto;
    text-align: center;
  }

  #map {
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
  }
}

